/* ----------------------------------------------------------------------------*\
  Search trigger
  Toggle searchbox on desktop
\* ----------------------------------------------------------------------------*/

import './polyfillsvgClassList';

/*  Component
\*----------------------------------------------------------------------------*/

class Accordeon {
  constructor() {
    // Bind `this` to the method so `this` always equals the Accordeon instance
    this.init = this.init.bind(this);
    this.toggle = this.toggle.bind(this);
    this.initAccordeon = this.initAccordeon.bind(this);
    this.makePie = this.makePie.bind(this);
    this.handleMouseOverShape = this.handleMouseOverShape.bind(this);
    this.handleClickOnShape = this.handleClickOnShape.bind(this);
  }

  init($pie) {
    // cache DOM objects
    this.$pie = $pie;
    this.$text1 = $pie.querySelector('.js--pie__text-1');
    this.$text2 = $pie.querySelector('.js--pie__text-2');
    this.$text3 = $pie.querySelector('.js--pie__text-3');
    this.$accordeon = $pie.querySelector('.js--accordeon');
    this.$cards = $pie.querySelectorAll('.js--pie__card');
    this.$items = this.$accordeon.querySelectorAll('[data-accordeon-item-id]');
    this.$piece = this.$pie.querySelector('.js--pie__piece');
    this.$pieces = this.$pie.querySelector('.js--pie__pieces');

    // speaks for itself
    this.initAccordeon();
    this.makePie();
  }

  initAccordeon() {
    // close items on init
    for (let i = 0; i < this.$items.length; i += 1) {
      const $item = this.$items[i];
      $item.classList.add('is-closed');
      const $button = $item.querySelector('.js--accordeon__toggle');
      const expanded = $button.getAttribute('aria-expanded') === 'true' || false;
      $button.setAttribute('aria-expanded', !expanded);
      // add click handler
      $button.addEventListener('click', (e) => this.toggle(e));
    }
  }

  toggle(e) {
    const itemId = e.currentTarget.dataset.target;
    const $item = this.$accordeon.querySelector(`[data-accordeon-item-id="${itemId}"]`);
    if ($item.classList.contains('is-closed')) {
      $item.classList.remove('is-closed');
      e.target.setAttribute('aria-expanded', true);
    } else {
      $item.classList.add('is-closed');
      e.target.setAttribute('aria-expanded', false);
    }
  }

  handleMouseOverShape($piece) {
    // Check if the pie part is already the active pie chart. When it is, do
    // nothing or else the pie chart will show some odd behaviour.
    if ($piece.classList.contains('is-active')) {
      return;
    }

    // set all inactive
    for (let i = 0; i < this.$allPieces.length; i += 1) {
      const $allPiece = this.$allPieces[i];
      $allPiece.classList.remove('is-active');
    }
    // Reappend the piece which will become the active piece to its parent
    // container making it the last child element. This will fix the issue in
    // IE11 with the z-indexing of the pie pieces where the active piece could
    // be positioned behind other pieces.
    $piece.parentNode.appendChild($piece);

    // Wait with doing all the other things which need to be done until the
    // browser has had the time to move the piece. A timeout of zero will do
    // the trick.
    setTimeout(() => {
      $piece.classList.add('is-active');
      // set texts
      this.$text1.textContent = $piece.dataSet.textA;
      this.$text2.textContent = $piece.dataSet.percentage;
      this.$text3.textContent = $piece.dataSet.textB;
      // cards over the pie
      for (let i = 0; i < this.$cards.length; i += 1) {
        const $card = this.$cards[i];
        // set all cards inactive
        $card.classList.remove('is-active');
        // set 1 active card
        if ($card.dataset.id === $piece.dataSet.accordeonItemId) {
          $card.classList.add('is-active');
        }
      }
    }, 0);
  }

  handleClickOnShape($piece) {
    if (
      $piece.dataSet.url !== undefined
      && $piece.dataSet.url !== ''
    ) {
      window.location.href = $piece.dataSet.url;
    }

    return this;
  }

  makePie() {
    const amount = this.$items.length;
    const circleLength = 130 * 2 * Math.PI;
    const pieceLength = circleLength / amount;

    const $text = this.$piece.querySelector('.js--pie__text');
    $text.setAttribute('transform', `rotate(${360 / amount / 2} 0 0)`);

    for (let i = 0; i < amount; i += 1) {
      const $newPiece = this.$piece.cloneNode(true);
      $newPiece.dataSet = this.$items[i].dataset;
      const $circle = $newPiece.querySelector('circle');
      // set piece size
      $circle.setAttribute('stroke-dasharray', `${pieceLength} ${circleLength}`);
      // set class+number for color
      $newPiece.classList.add(`c-pie__piece--${i}`);
      // rotate it
      $newPiece.setAttribute('transform', `rotate(${i * (360 / amount)} 320 320)`);
      // set text
      const $innerText = $newPiece.querySelector('.js--pie__inner-text');
      const $outerText = $newPiece.querySelector('.js--pie__outer-text');
      $innerText.textContent = (this.$items[i].dataset.heading);

      // if the rotation of the text is upside down, do some math to set it right!
      if (
        (i * (360 / amount)) + (360 / amount / 2) > 90
        && (i * (360 / amount)) + (360 / amount / 2) < 270
      ) {
        $outerText.setAttribute('transform', 'rotate(180 0 0)');
      }

      // add hover/tap event
      // const $subshape = $newPiece.querySelector('.c-pie__shape');
      $newPiece.addEventListener('mouseover', () => this.handleMouseOverShape($newPiece));
      $newPiece.addEventListener('click', () => this.handleClickOnShape($newPiece));
      // add new shape
      this.$pieces.appendChild($newPiece);
    }

    // cache all pieces
    this.$allPieces = this.$pieces.querySelectorAll('.js--pie__piece');
    // open de first piece by default
    this.handleMouseOverShape(this.$pieces.querySelector('.js--pie__piece'));
  }
}

window.addEventListener('load', () => {
  const $pies = document.querySelectorAll('.js--pie');
  [...$pies].forEach(($pie) => {
    const $acc = new Accordeon();
    $acc.init($pie);
  });
}, false);

class PackageComparer extends HTMLElement {
  connectedCallback() {
    this.currentSlide = 0;
    this.$container = this.querySelector("[data-package-comparer-container]");
    this.$tabs = this.querySelectorAll("[data-package-comparer-tab]");
    this.$slides = this.querySelectorAll("[data-package-comparer-slide]");

    this.observeSlide();

    [...this.$tabs].forEach(($tab) => {
      $tab.addEventListener("click", () => {
        this.currentSlide = [...this.$tabs].indexOf($tab);
        this.scrollToCurrentSlide();
      });
    });
  }

  scrollToCurrentSlide() {
    const w = this.$container.getBoundingClientRect().width;
    // scroll to slide
    this.$container.scroll({
      left: w * this.currentSlide,
      behavior: "smooth",
    });
  }

  observeSlide() {
    // observe slides with intersection observer
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // update index
            this.currentSlide = [...this.$slides].findIndex(
              ($slide) => $slide === entry.target,
            );
            this.updateTabs();
          }
        });
      },
      {
        threshold: 0.5,
        root: this.$container,
      },
    );

    [...this.$slides].forEach(($slide) => {
      observer.observe($slide);
    });
  }

  updateTabs() {
    [...this.$tabs].forEach(($tab) => {
      $tab.classList.remove("is-active");
    });
    this.$tabs[this.currentSlide].classList.add("is-active");
  }
}

window.customElements.define("package-comparer", PackageComparer);

// when switching domain set session cookie with domain id
// when page is loaded, check if session cookie exists
// if not, go to default (first) domain
// if yes, go to domain with the id.
// set menu-items selected in the tree of domain id.

// activate this module on top-level of component

const selector = {
    base: '.js-setActiveItem',
    domainTrigger: 'data-activestate-domain',
    subdomainTrigger: 'data-activestate-subdomain',
    mainMenu: 'data-mainmenu'
};
const sessionNames = {
    navlinkClicked: 'NavLinkClicked',
    domainId: 'domainId',
    subdomainId: 'subdomainId'
}

class ActiveState {
    constructor(element) {
        this.component = element;

        if (!this.component) {
            return;
        }
        
        this.domainTriggers = this.component.querySelectorAll(`[${selector.domainTrigger}]`);
        this.subdomainTriggers = this.component.querySelectorAll(`[${selector.subdomainTrigger}]`);
        this.mainMenus = this.component.querySelectorAll(`[${selector.mainMenu}]`);

        this.navigationItemClicked = false;

        this._init();
    }

    _loadState() {
        const domainId = sessionStorage.getItem(sessionNames.domainId) || [...this.domainTriggers][0]?.dataset.activestateDomain || -1;
        const subdomainId = sessionStorage.getItem(sessionNames.subdomainId) || [...this.subdomainTriggers][0]?.dataset.activestateSubdomain || -1;

        if (domainId != -1 && [...this.domainTriggers].filter((x) => x.classList?.contains('is-selected')).length > 1) {
            // remove selected state from all other triggers
            this.domainTriggers.forEach((menuItem) => {
                if (menuItem.dataset.activestateDomain != domainId) {
                    this._removeSelectedState(menuItem);
                }                
            });

            this.mainMenus.forEach((menu) => {
                if (menu.id != domainId)
                {
                    menu.classList?.remove('is-active');
                    menu.setAttribute("aria-hidden", "true");
                }
            });
        }

        if(this.navigationItemClicked && subdomainId != -1) {
            // remove selected state from all other triggers
            this.subdomainTriggers.forEach((menuItem) => {
                if (menuItem.dataset.activestateSubdomain != subdomainId) {
                    this._removeSelectedState(menuItem);
                }                
            });
        }

        // if navigated from outside the menu, we reset selected states.
        if (!this.navigationItemClicked) {
            const activeMainMenu = [...this.mainMenus].find(x => x?.classList?.contains('is-active'));
            
            activeMainMenu?.querySelectorAll('.is-selected').forEach((item) => {
                this._removeSelectedState(item);
            });
        }
    }

    _removeSelectedState(item) {
        item.classList.remove('is-selected');
        item.setAttribute(
            "aria-expanded",
            false
        );
    }

    _setDomain(trigger) {
        sessionStorage.setItem(sessionNames.domainId, trigger.dataset?.activestateDomain);
    }

    _setSubDomain(trigger) {
        const subdomain = trigger.dataset?.activestateSubdomain;
        if (subdomain) {
            sessionStorage.setItem(sessionNames.subdomainId, subdomain);
        }        
    }

    _bindEvents() {
        // set session storage if item in menu is clicked
        this.component.addEventListener('click', (event) => {
            if (event.target.tagName === 'A') {
                sessionStorage.setItem(sessionNames.navlinkClicked, true);
            };
        });

        this.domainTriggers.forEach( (trigger) => {
            trigger.addEventListener('click', () => this._setDomain(trigger));
        });

        this.subdomainTriggers.forEach( (subtrigger) => {
            subtrigger.addEventListener('click', () => this._setSubDomain(subtrigger));
        });
    }

    /**
     * Check if we navigated from the navigation menu
     */
    _setInitialState() {
        this.navigationItemClicked = sessionStorage.getItem(sessionNames.navlinkClicked) ?? false;
        sessionStorage.removeItem(sessionNames.navlinkClicked);
    }

    _init() {
        this._bindEvents();
        this._setInitialState();
        this._loadState();
    }
}

document.querySelectorAll(selector.base).forEach((element) => {
    new ActiveState(element);
});
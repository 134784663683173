/* ----------------------------------------------------------------------------*\
  Navigation
  Toggle navigation on mobile
\* ----------------------------------------------------------------------------*/

/**
 * Use strict as per:
 * http://ejohn.org/blog/ecmascript-5-strict-mode-json-and-more/
 */

/**
 * Check if clrs object exists, otherwise create it
 */
const clrs = window.clrs || {};

/**
 * Jshint global references
 * Please define all third party plugins that you use to not break jshinting
 */

/*  Component
\*----------------------------------------------------------------------------*/

clrs.navToggle = (function navToggle() {
  /* ======================================================================== *\
      PRIVATE VARIABLES
  \* ======================================================================== */
  const cssClass = {
    isActive: "is-active",
    noScroll: "no-scroll",
  };

  const selector = {
    iconHamburger: ".js-navigation-hamburger-icon",
    header: ".js-header",
    labelTriggerToggle: ".js-navigation-trigger-caption",
    mobileMainMenu: ".js-navigation-mobile-main",
    triggerToggleState: ".js-navigation-trigger",
  };
  /* == PRIVATE VARIABLES =================================================== */

  /* ======================================================================== *\
      PRIVATE METHODS
  \* ======================================================================== */
  /**
   * Toggles the class assignment on the provided element.
   *
   * @param {HTMLElement} element
   * @param {string} className
   */
  function toggleCssAssignment(element, className) {
    if (element === undefined || element === null) {
      return;
    }

    if (element instanceof NodeList) {
      element.forEach((item) => {
        item.classList.toggle(className);
      });
    } else {
      element.classList.toggle(className);
    }
  }

  /**
   * Updates the label for the button to open/close the menu.
   *
   *
   * @param {boolean} isOpen The current state of the menu; true when the menu
   *        is open and otherwise false.
   */
  function updateMenuLabel(isOpen) {
    const navBtnLabel = document.querySelector(selector.labelTriggerToggle);
    if (navBtnLabel === null) {
      return;
    }

    navBtnLabel.textContent = isOpen
      ? navBtnLabel.dataset.close
      : navBtnLabel.dataset.open;
  }

  /**
   *
   */
  function toggleMenu() {
    const isOpen = document.body.classList.contains(cssClass.noScroll);

    if (!isOpen && clrs.toggleSearch !== undefined) {
      clrs.toggleSearch.closeSearch();
    }

    document.body.classList.toggle(cssClass.noScroll);

    const header = document.querySelector(selector.header);
    const hamburgerIcon = document.querySelector(selector.iconHamburger);
    const navPrimary = document.querySelectorAll(selector.mobileMainMenu);

    toggleCssAssignment(header, cssClass.isActive);
    toggleCssAssignment(hamburgerIcon, cssClass.isActive);
    toggleCssAssignment(navPrimary, cssClass.isActive);

    updateMenuLabel(header.classList.contains(cssClass.isActive));
  }
  /* == PRIVATE METHODS ===================================================== */

  /* ======================================================================== *\
      PUBLIC API
  \* ======================================================================== */

  /**
   * Closes the main menu if it is open.
   *
   * @returns
   */
  function closeMenu() {
    // First make sure the menu is actually open, in case it is closed we can
    // just exit.
    if (!document.body.classList.contains(cssClass.noScroll)) {
      return;
    }

    // Close the menu.
    toggleMenu();
  }

  /* == PUBLIC API ========================================================== */

  /* ======================================================================== *\
      INITIALIZATION
  \* ======================================================================== */

  /**
   *
   */
  function init() {
    const $navToggle = document.querySelector(selector.triggerToggleState);
    if ($navToggle !== null) {
      $navToggle.addEventListener("click", toggleMenu);
    }
  }

  /* == INITIALIZATION ====================================================== */

  /* ======================================================================== *\
      EXPORTS
  \* ======================================================================== */

  return {
    closeMenu,
    init,
  };

  /* == EXPORTS ============================================================= */
})();

// Init the navigation functionality on window load.
window.addEventListener("load", () => clrs.navToggle.init());

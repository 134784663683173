/* ----------------------------------------------------------------------------*\
  FAQ
\* ----------------------------------------------------------------------------*/

/**
 * Use strict as per:
 * http://ejohn.org/blog/ecmascript-5-strict-mode-json-and-more/
 */

/**
 * Jshint global references
 * Please define all third party plugins that you use to not break jshinting
 */


/*  Component
\*----------------------------------------------------------------------------*/

class DropDownPos {
  init() {
    const elem = document.querySelectorAll('.c-navigation-secondary--top-nav .c-navigation-secondary .c-navigation-top__dropdown');

    for (let i = 0; i < elem.length; i += 1) {
      elem[i].classList.remove('c-navigation-top__dropdown--outside');
      const bounding = elem[i].getBoundingClientRect();

      if (bounding.right > (window.innerWidth || document.documentElement.clientWidth)) {
        elem[i].classList.add('c-navigation-top__dropdown--outside');
      } else {
        elem[i].classList.remove('c-navigation-top__dropdown--outside');
      }
    }

    return this;
  }
}

window.addEventListener('load', () => {
  new DropDownPos().init();
}, false);

window.addEventListener('resize', () => {
  new DropDownPos().init();
}, false);

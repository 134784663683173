/* ----------------------------------------------------------------------------*\
  FAQ
\* ----------------------------------------------------------------------------*/

/**
 * Use strict as per:
 * http://ejohn.org/blog/ecmascript-5-strict-mode-json-and-more/
 */

/**
 * Check if clrs object exists, otherwise create it
 */
const clrs = window.clrs || {};

/**
 * Jshint global references
 * Please define all third party plugins that you use to not break jshinting
 */


/*  Component
\*----------------------------------------------------------------------------*/

clrs.toggleFaqItem = {
  init() {
    const faqList = document.querySelectorAll('.js--faq-list');

    for (let i = 0; i < faqList.length; i += 1) {
      faqList[i].addEventListener('click', this.showFaqContent);
    }
  },

  showFaqContent(e) {
    if (e.target && e.target.matches('.js--faq-toggle')) {
      if (e.target.parentNode.classList.contains('is-active')) {
        e.target.parentNode.classList.remove('is-active');
      } else {
        e.target.parentNode.classList.add('is-active');
      }
    }
  },
};

window.addEventListener('load', () => {
  clrs.toggleFaqItem.init();
}, false);

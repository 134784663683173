
/* ----------------------------------------------------------------------------*\
  Wizard
\* ----------------------------------------------------------------------------*/

/* ========================================================================== *\
    PRIVATE VARIABLES
\* ========================================================================== */

const defaultCategory = 'category';

const cssClass = {
  baseClass: 'js--wizard',
  isDisabled: 'is-disabled',
  isHidden: 'is-hidden',
};

const dataKey = {
  category: 'category',
};

const propertyKey = {
  baseElement: '_baseElement',
};

const scrollTarget = {
  base: 'base',
  solutions: 'solutions',
};

const selector = {
  baseElement: `.${cssClass.baseClass}`,
  notification: '.js--wizard-notification',
  selectedOption: '.js--wizard-option:checked',
  solutionsContainer: '.js--wizard-solutions',
  solutionItem: '.js--wizard-package',
  triggerShowSelection: '.js--wizard-filter',
  wizardQuestion: '.js--wizard-question',
};

/* == PRIVATE VARIABLES ===================================================== */



/* ========================================================================== *\
    PRIVATE METHODS
\* ========================================================================== */

/**
 * Adds or removes a CSS class name from the provided element.
 *
 * @param {HTMLElement} element The element to add/remove the CSS class from.
 * @param {string} className The name of the CSS class to add/remove.
 * @param {boolean} applyClass When true the CSS class will be added to the
 *        provided element; otherwise the CSS class is removed.
 */
function updateCssAssignment(element, className, applyClass) {
  if (
    element === null
  ) {
    return;
  }

  const methodName = (applyClass)
    ? 'add'
    : 'remove';

  element.classList[methodName](className);
}

/**
 * Filters the solutions in the wizard. Only the solutions which match all the
 * selected options will be visible.
 *
 * @param {string} categoryId The name of the category to filter.
 */
function filterOptionsForCategory(categoryId) {
  const items = this.baseElement.querySelectorAll(`${selector.solutionItem}[data-${categoryId}]`);
  const selectedValues = this.selectedOptions;

  // Iterate over all the solutions.
  for (let itemIndex = 0; itemIndex < items.length; itemIndex += 1) {
    const
      item = items[itemIndex];
    /** @type {Array<string>} */
      // Get the attribute value and convert it to an array.
    const
      values = item.dataset[categoryId].split(' ');

    let
      hasAllSelectedValues = true;
    // Iterate over all the selected options. Don't use a forEach as we want to
    // exit early in case the current solution doesn't match.
    for (let index = 0; index < selectedValues.length; index += 1) {
      // Check if the current selected option is present in the attribute value
      // of the current solution. When it is NOT present, hide the solution and
      // continue with the next iteration.
      hasAllSelectedValues = hasAllSelectedValues && (values.indexOf(selectedValues[index]) > -1);
    }

    // When not all selected values are present on the item, make it hidden;
    // when all values are present remove the class which hides the item.
    updateCssAssignment(item, cssClass.isHidden, !hasAllSelectedValues);
  }
}

/**
 * Makes the container with the solutions visible for the wizard.
 *
 * @memberof Wizard
 */
function makeSolutionsVisible(scrollToTarget) {
  // Get the container with the solutions, when it can't be found just exit.
  const container = this.baseElement.querySelector(selector.solutionsContainer);
  const scrollElement = (scrollToTarget === scrollTarget.solutions)
    ? container
    : this.baseElement;
  if (container === null) {
    return;
  }

  // Make the solutions visible.
  container.classList.remove(cssClass.isHidden);
  // Scroll the document so the base element is at the top of the viewport.
  scrollElement.scrollIntoView({
    behavior: 'smooth',
  });
}

/**
 * Assigns a class to the filter button to give it a disabled look and feel. Do
 * not actually disable the button, in case the user clicks on the button while
 * it is faux disabled, we will show a message telling the user what to do.
 *
 * @param {boolean} isDisabled When true the button will have the disabled look
 *        and feel; otherwise the button will appear active.
 *
 * @memberof Wizard
 */
function setFilterButtonState(isDisabled) {
  const triggerShowSolutions = this.baseElement.querySelector(selector.triggerShowSelection);

  updateCssAssignment(triggerShowSolutions, cssClass.isDisabled, isDisabled);
}

/**
 * Checks if the provided element has another wizard as its sibling. When it
 * does the next wizard will be made visible.
 *
 * @param {HTMLElement} baseElement
 */
function showNextSiblingWizard(baseElement) {
  const sibling = baseElement.nextElementSibling;
  // First make sure the provided element has a next sibling.
  if (sibling === null) {
    return;
  }

  // Check if the sibling has the base class, when it does make sure it is no
  // longer hidden.
  if (sibling.classList.contains(cssClass.baseClass)) {
    sibling.classList.remove(cssClass.isHidden);
  }
}

/**
 * Changes the visibility of the notification telling the user what to do in
 * order to see the matching solutions.
 *
 * @param {boolean} isVisible When true the notification will become visible,
 *        the document will be scrolled so the notification will be visible.
 *        When parameter is false the notification will be hidden.
 *
 * @memberof Wizard
 */
function setNotificationVisibility(isVisible) {
  const notification = this.baseElement.querySelector(selector.notification);

  if (isVisible) {
    this.baseElement.scrollIntoView({
      behavior: 'smooth',
    });
  }

  updateCssAssignment(notification, cssClass.isHidden, !isVisible);
}

/* == PRIVATE METHODS ======================================================= */



/* ========================================================================== *\
    EVENT HANDLING
\* ========================================================================== */

/**
 * Handles the event on the filter button. When it is disabled we need to show a
 * notification to the user; otherwise the solutions need to be filtered.
 *
 * @param {Event} event
 *
 * @memberof Wizard
 */
function onShowSolutionsClicked(event) {
  // Check if the trigger has the disabled class, when it does make the
  // notification visible and exit the method.
  if (event.currentTarget.classList.contains(cssClass.isDisabled)) {
    setNotificationVisibility.call(this, true);

    return;
  }

  filterOptionsForCategory.call(this, defaultCategory);
  makeSolutionsVisible.call(this, scrollTarget.solutions);
}

/**
 * Handles the change event for a wizard which does have a filter button. When
 * an answer is changed it is our trigger to check if all questions have been
 * answered and update the filter button state accordingly.
 *
 * @memberof Wizard
 */
function onAnswerChanged() {
  const questions = this.baseElement.querySelectorAll(selector.wizardQuestion);
  const selectedOptions = this.baseElement.querySelectorAll(selector.selectedOption);
  const allQuestionsAnswered = questions.length === selectedOptions.length;

  setNotificationVisibility.call(this, false);
  setFilterButtonState.call(this, !allQuestionsAnswered);
}

/**
 * Binds the event listeners needed for the widget so it can react properly to
 * the user interacting with the widget.
 *
 * @memberof Wizard
 */
function bindEvents() {
  const triggerShowSolutions = this.baseElement.querySelector(selector.triggerShowSelection);
  if (triggerShowSolutions !== null) {
    triggerShowSolutions.addEventListener('click', onShowSolutionsClicked.bind(this));
  }

  this.baseElement.addEventListener('change', onAnswerChanged.bind(this));
}

/**
 * Handles the change event in a wizard which doesn't have a filter button.
 *
 * @param {Event} event
 *
 * @memberof Wizard
 */
function onTriggerlessWizardChanged(event) {
  // Get the category of the option which was just selected by the user.
  const category = event.target.dataset[dataKey.category];

  filterOptionsForCategory.call(this, category);
  makeSolutionsVisible.call(this, scrollTarget.base);
  showNextSiblingWizard(this.baseElement);
}

/**
 * Binds the event listeners needed for the widget when it has no trigger to
 * show the matching solutions so it can react properly to the user interacting
 * with the widget.
 *
 * @memberof Wizard
 */
function bindWizardWithoutTriggerEvents() {
  this.baseElement.addEventListener('change', onTriggerlessWizardChanged.bind(this));
}

/* == EVENT HANDLING ======================================================== */

/* ========================================================================== *\
    PUBLIC API
\* ========================================================================== */
class Wizard {
  constructor(baseElement) {
    this[propertyKey.baseElement] = baseElement;

    const
      triggerShowSolutions = baseElement.querySelector(selector.triggerShowSelection);

    if (triggerShowSolutions === null) {
      bindWizardWithoutTriggerEvents.call(this);
    } else {
      setFilterButtonState.call(this, true);
      bindEvents.call(this);
    }
  }
  /* == CONSTRUCTOR ========================================================= */


  /* ======================================================================== *\
      INSTANCE PROPERTIES
  \* ======================================================================== */

  /* ---------------------------------- *\
      baseElement (read-only)
  \* ---------------------------------- */
  /**
   * @type {HTMLElement}
   *
   * @readonly
   * @memberof Wizard
   */
  get baseElement() {
    return this[propertyKey.baseElement];
  }
  /* -- baseElement (read-only) ---------- */


  /* ---------------------------------- *\
      selectedOptions (read-only)
  \* ---------------------------------- */
  /**
   * @type {Array<string>}
   *
   * @readonly
   * @memberof Wizard
   */
  get selectedOptions() {
    const options = this.baseElement.querySelectorAll(selector.selectedOption);
    const values = [];

    for (let index = 0; index < options.length; index += 1) {
      values.push(options[index].value);
    }
    return values;
  }
  /* -- selectedOptions (read-only) ---------- */

  /* == INSTANCE PROPERTIES ================================================= */
}
/* == PUBLIC API ============================================================ */


window.addEventListener('load', () => {
  const wizards = document.querySelectorAll(selector.baseElement);
  for (let index = 0; index < wizards.length; index += 1) {
    return new Wizard(wizards[index]);
  }
  return null;
}, false);

class VideoEmbed extends HTMLElement {
  constructor() {
    super();
    this.start = this.start.bind(this);
  }

  connectedCallback() {
    this.$video = this.querySelector('[data-video-embed-video]');

    this.addEventListener('start', () => {
      this.start();
    });

    this.addEventListener('stop', () => {
      this.stop();
    });
  }

  start() {
    this.$video.hidden = false;
    this.$video.src = this.$video.dataset.src;
  }

  stop() {
    this.$video.removeAttribute('src');
  }
}

customElements.define('video-embed', VideoEmbed);

const selector = {
		baseElement: ".js-outsideclick",
	},
	classNames = {
		active: "is-active",
		targetActive: "is-selected",
	};

class OutsideClickListener {
	constructor(container) {
		this.container = container;
		this.isOpen = false;

		this.init();
	}

	init() {
		document.addEventListener("click", (event) => {
			if (!this.container.contains(event.target)) {
				const mainActiveElements = this.container.querySelectorAll('[data-menu-group="main"] .is-selected');

				mainActiveElements.forEach((item) => {
					item.classList?.remove(classNames.targetActive);
					item.setAttribute("aria-expanded", "false");
				});
				
				const submenuActiveElements = this.container.querySelectorAll('[data-menu-sub]');

				submenuActiveElements.forEach((item) => {
					item.classList?.remove(classNames.active);
					item.setAttribute("aria-hidden", "true");
				});
			}
		});
	}
}

[...document.querySelectorAll(selector.baseElement)].forEach((element) => {
	new OutsideClickListener(element);
});

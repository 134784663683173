const selector = {
    base: 'iframe[src^="//go.sazas.nl"], iframe[src^="https://go.sazas.nl"]'
};

class PardotPostmessage {
    constructor(element) {
        this.component = element;        
        this.init();
    }

    bindEvents() {
        window.addEventListener('message', (event) => {
            
            // check if the message is from Pardot
            if (event.origin !== 'https://go.sazas.nl') return;

            // set height for iFrame with an extra spacing of 50px.
            if (typeof event.data === 'number') {
                this.component.style.height = `${event.data + 50}px`;
            }
        });
    }

    init() {
        this.bindEvents();
    }
}

document.querySelectorAll(selector.base).forEach((element) => {
    const instance = new PardotPostmessage(element);
});
/**
 * Links that have a 'data-ping="https://some.domain"' will send a ping to some domain
 */

[...document.querySelectorAll('[data-ping]')].forEach(($el) => {
  $el.addEventListener('click', () => {
    fetch($el.dataset.ping, {
      credentials: 'include',
    });
  });
});

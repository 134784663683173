const parseBirthDate = (day, month, year) => {
  // month is index based, so substracting 1 to parse it correctly
  const date = new Date(year, month - 1, day);
  // check if the parsed date matches the form values
  if (date.getFullYear() === parseFloat(year)
    && date.getMonth() === parseFloat(month) - 1
    && date.getDate() === parseFloat(day)) {
    return date;
  }

  return false;
};

class Calculator {
  constructor($el) {
    this.$el = $el;
    this.$birthDateForm = this.$el.querySelector('.js--calculator__form');
    this.$birthDateDay = this.$birthDateForm.querySelector('.js--calculator__input-day');
    this.$birthDateMonth = this.$birthDateForm.querySelector('.js--calculator__input-month');
    this.$birthDateYear = this.$birthDateForm.querySelector('.js--calculator__input-year');
    this.$formError = this.$el.querySelector('.js--calculator__form-error');
    this.$formHint = this.$el.querySelector('.js--calculator__form-hint');
    this.$result = this.$el.querySelector('.js--calculator__result');
    this.$table = this.$el.querySelector('.js--calculator__table');
    this.$tableRows = this.$table.querySelectorAll('tbody > tr');
  }

  init() {
    this.handleSubmit();
  }

  handleSubmit() {
    this.$birthDateForm.setAttribute('novalidate', '');
    this.$birthDateForm.addEventListener('submit', (e) => {
      e.preventDefault();
      this.clearError();
      // hide table and possible unhidden row initially
      this.$result.hidden = true;
      [...this.$tableRows].forEach(($row) => {
        $row.hidden = true;
      });

      // check entry + pattern
      if (!this.$birthDateDay.validity.valid
        || !this.$birthDateMonth.validity.valid
        || !this.$birthDateYear.validity.valid
      ) {
        return this.setError(this.$el.dataset.calculatorInvalidDate);
      }
      // parse input, convert to date object
      const birthDate = parseBirthDate(
        this.$birthDateDay.value, this.$birthDateMonth.value, this.$birthDateYear.value,
      );
      if (!birthDate) {
        return this.setError(this.$el.dataset.calculatorInvalidDate);
      }
      // find a matching table row
      const rowFound = this.findRow(birthDate);
      // if row found, unhide the table
      if (rowFound) {
        this.$result.hidden = false;
        this.$formHint.hidden = true;
      }

      return true;
    });
  }

  findRow(birthDate) {
    let result = false;

    const $firstRow = this.$tableRows[0];

    const startDate = new Date($firstRow.dataset.startdate);

    if (birthDate < startDate) {
      this.setError(this.$el.dataset.calculatorTooOld);
      return false;
    }

    const endDate = new Date(this.$tableRows[this.$tableRows.length - 1].dataset.enddate);

    if (birthDate > endDate) {
      this.setError(this.$el.dataset.calculatorTooYoung);
      return false;
    }

    [...this.$tableRows].forEach(($row) => {
      const rowStartDate = new Date($row.dataset.startdate);
      const rowEndDate = new Date($row.dataset.enddate);

      if (birthDate > rowStartDate && birthDate < rowEndDate) {
        $row.hidden = false;
        result = true;
      }
    });

    return result;
  }

  setError(errorMessage) {
    this.$birthDateDay.setAttribute('aria-invalid', 'true');
    this.$birthDateDay.setAttribute('aria-describedby', `${this.$birthDateDay.id}_error`);
    this.$birthDateMonth.setAttribute('aria-invalid', 'true');
    this.$birthDateMonth.setAttribute('aria-describedby', `${this.$birthDateMonth.id}_error`);
    this.$birthDateYear.setAttribute('aria-invalid', 'true');
    this.$birthDateYear.setAttribute('aria-describedby', `${this.$birthDateYear.id}_error`);
    this.$formError.innerHTML = errorMessage;
    this.$formError.hidden = false;
  }

  clearError() {
    this.$birthDateDay.removeAttribute('aria-invalid');
    this.$birthDateDay.removeAttribute('aria-describedby');
    this.$birthDateMonth.removeAttribute('aria-invalid');
    this.$birthDateMonth.removeAttribute('aria-describedby');
    this.$birthDateYear.removeAttribute('aria-invalid');
    this.$birthDateYear.removeAttribute('aria-describedby');
    this.$formError.hidden = true;
    this.$formHint.hidden = false;
  }
}

[...document.querySelectorAll('.js--calculator')].forEach(($calculator) => {
  new Calculator($calculator).init();
});

class ScrollHelper {
  constructor($el) {
    this.$el = $el;
    this.$scroller = this.$el.querySelector(".js--scroll-helper__scroller");
    // the item where should be scrolled to initally
    this.$highlight = this.$el.querySelector(".js--scroll-helper__focus");
    this.$btnLeft = this.$el.querySelector(".js--scroll-helper__btn-left");
    this.$btnRight = this.$el.querySelector(".js--scroll-helper__btn-right");
  }

  init() {
    this.scrollToHighlight();
    if (this.$btnLeft && this.$btnRight) this.addEventListeners();
  }

  scrollToHighlight() {
    if (!this.$highlight) {
      return;
    }

    const dims = this.$highlight.getBoundingClientRect();
    const x = dims.x - (window.innerWidth - dims.width) / 2;
    // scroll highlighted column to center of viewport
    this.$scroller.scrollTo(x, 0);
  }

  addEventListeners() {
    this.$btnLeft.addEventListener("click", () => {
      this.scroll("left");
    });

    this.$btnRight.addEventListener("click", () => {
      this.scroll("right");
    });
  }

  scroll(direction) {
    let x = this.$scroller.scrollLeft - 220;
    // change direction, default is left
    if (direction === "right") {
      x = this.$scroller.scrollLeft + 220;
    }

    this.$scroller.scroll(x, 0);
  }
}

const $els = document.querySelectorAll(".js--scroll-helper");

[...$els].forEach(($el) => {
  new ScrollHelper($el).init();
});

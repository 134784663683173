/* ----------------------------------------------------------------------------*\
  Search trigger
  Toggle searchbox on desktop
\* ----------------------------------------------------------------------------*/

/**
 * Use strict as per:
 * http://ejohn.org/blog/ecmascript-5-strict-mode-json-and-more/
 */

/**
 * Check if clrs object exists, otherwise create it
 */
const clrs = window.clrs || {};

/**
 * Jshint global references
 * Please define all third party plugins that you use to not break jshinting
 */


/*  Component
\*----------------------------------------------------------------------------*/

clrs.toggleSearch = (function toggleSearch() {
  /* ======================================================================== *\
      PRIVATE VARIABLES
  \* ======================================================================== */
  const
    cssClass = {
      isActive: 'search-is-active',
      isExpanded: 'is-expanded',
    };

  const selector = {
    inputMobileSearch: '.js-mobile-search .js--search-input',
    mobileSearchContainer: '.js-mobile-search',
    navigationWrapper: '.js-navigation-wrapper',
    triggerHideSearch: '.js-hide-search',
    triggerShowSearch: '.js-show-search',
    triggerToggleMobileSearch: '.js-show-search-mobile',
  };
  /* == PRIVATE VARIABLES =================================================== */

  /* ======================================================================== *\
      PRIVATE METHODS
  \* ======================================================================== */

  /**
   *
   * @param {HTMLElement} element
   * @param {string} className
   * @param {boolean} assignClass When true the provided class name will be
   *        assigned to the element; otherwise the class is removed.
   */
  function updateClassAssignment(element, className, assignClass) {
    if (
      element === undefined
      || element === null
    ) {
      return;
    }

    const methodName = (assignClass) ? 'add' : 'remove';

    element.classList[methodName](className);
  }

  /**
   *
   * @param {HTMLElement} element
   * @param {string} eventName
   * @param {Function} handler
   */
  function addEventListener(element, eventName, handler) {
    if (
      element === undefined
      || element === null
    ) {
      return;
    }

    element.addEventListener(eventName, handler);
  }

  /**
   * Closes the main menu, it should be open when the mobile search form
   * is open.
   *
   * @returns
   */
  function closeMenu() {
    if (
      clrs.navToggle === undefined
    ) {
      return;
    }

    clrs.navToggle.closeMenu();
  }

  /**
   * Sets the focus on the input for the search on the mobile breakpoints.
   */
  function focusMobileSearchInput() {
    const
      searchInput = document.querySelector(selector.inputMobileSearch);
    if (searchInput !== null) {
      searchInput.focus();
    }
  }

  /* == PRIVATE METHODS ===================================================== */


  /* ======================================================================== *\
      EVENT HANDLIN,
  \* ======================================================================== */
  /**
   * Handles the event dispatched when the user interacts with the element to
   * hide the searchbar on desktop.
   *
   * @param {Event} event The event as dispatched by the browser.
   */
  function onHideSearchBar() {
    const navigationWrapper = document.querySelector(selector.navigationWrapper);

    updateClassAssignment(navigationWrapper, cssClass.isActive, false);
  }

  /**
   * Handles the event dispatched when the user interacts with the element to
   * show/hide the search bar on mobile.
   */
  function onShowMobileSearchBar() {
    const
      searchFormPalm = document.querySelector(selector.mobileSearchContainer);
    if (searchFormPalm === null) {
      return;
    }

    const
      searchIsExpanded = searchFormPalm.classList.contains(cssClass.isExpanded);
    updateClassAssignment(searchFormPalm, cssClass.isExpanded, !searchIsExpanded);

    if (!searchIsExpanded) {
      closeMenu();
      focusMobileSearchInput();
    }
  }

  /**
   * Handles the event dispatched when the user interacts with the element to
   * show the searchbar on desktop.
   *
   * @param {Event} event The event as dispatched by the browser.
   */
  function onShowSearchBar() {
    const navigationWrapper = document.querySelector(selector.navigationWrapper);

    updateClassAssignment(navigationWrapper, cssClass.isActive, true);
  }

  /* == EVENT HANDLING ====================================================== */



  /* ======================================================================== *\
      PUBLIC METHODS
  \* ======================================================================== */

  /**
   * Closes the mobile search form in case it is open.
   */
  function closeSearch() {
    const
      searchFormPalm = document.querySelector(selector.mobileSearchContainer);
    if (searchFormPalm === null) {
      return;
    }

    updateClassAssignment(searchFormPalm, cssClass.isExpanded, false);
  }
  /* == PUBLIC METHODS ====================================================== */


  /* ======================================================================== *\
      INITIALIZATION
  \* ======================================================================== */
  /**
   * Initializes the search trigger functionality.
   */
  function init() {
    const showSearch = document.querySelector(selector.triggerShowSearch);
    const hideSearch = document.querySelector(selector.triggerHideSearch);
    const showSearchMobile = document.querySelector(selector.triggerToggleMobileSearch);

    addEventListener(showSearch, 'click', onShowSearchBar);
    addEventListener(hideSearch, 'click', onHideSearchBar);
    addEventListener(showSearchMobile, 'click', onShowMobileSearchBar);
  }
  /* == INITIALIZATION ====================================================== */



  /* ======================================================================== *\
      EXPORTS
  \* ======================================================================== */

  return {
    closeSearch,
    init,
  };

  /* == EXPORTS ============================================================= */
}());

// Init the toggle functionality on window load.
window.addEventListener('load', () => clrs.toggleSearch.init());

class HeaderUsps extends HTMLElement {
  connectedCallback() {
    this.$items = this.querySelectorAll("[data-header-usps-item]");
    this.currentItem = 0;

    setInterval(() => {
      this.$items[this.currentItem].classList.remove("is-active");
      this.currentItem += 1;
      if (this.currentItem > this.$items.length - 1) {
        this.currentItem = 0;
      }
      this.$items[this.currentItem].classList.add("is-active");
    }, 3000);
  }
}

window.customElements.define("header-usps", HeaderUsps);

class FloatingButton extends HTMLElement {
  constructor() {
    super();
    this.hideFloater = this.hideFloater.bind(this);
  }

  static get observedAttributes() {
    return ["show"];
  }

  get show() {
    return this.hasAttribute("show");
  }

  set show(value) {
    if (value) {
      this.setAttribute("show", "");
    } else {
      this.removeAttribute("show");
    }
  }

  connectedCallback() {
    this.show = false;
    this.$trigger = this.querySelector("[data-floating-button-trigger]");
    this.$floater = this.querySelector("[data-floating-button]");

    if (this.$trigger) {
      this.bindListeners();
    }
  }

  bindListeners() {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].boundingClientRect.bottom < 0) {
        this.show = true;
        // this.showStickyBar();
      } else {
        this.show = false;
        // this.hideStickyBar();
      }
    });

    observer.observe(this.$trigger);
  }

  hideFloater() {
    this.$floater.hidden = true;
    this.$floater.removeEventListener("transitionend", this.hideFloater);
  }

  attributeChangedCallback(name) {
    if (name === "show") {
      if (this.show) {
        this.$floater.hidden = false;
        requestAnimationFrame(() => {
          this.$floater.classList.add("show");
        });
        return;
      }

      this.$floater.classList.remove("show");

      this.$floater.addEventListener("transitionend", this.hideFloater);
    }
  }
}

customElements.define("floating-button", FloatingButton);

/* ----------------------------------------------------------------------------*\
  Search trigger
  Toggle searchbox on desktop
\* ----------------------------------------------------------------------------*/

/**
 * Use strict as per:
 * http://ejohn.org/blog/ecmascript-5-strict-mode-json-and-more/
 */

/**
 * Check if clrs object exists, otherwise create it
 */
const clrs = window.clrs || {};

/**
 * Jshint global references
 * Please define all third party plugins that you use to not break jshinting
 */


/*  Component
\*----------------------------------------------------------------------------*/

clrs.embedVideo = {
  init() {
    const $videoEmbeds = document.querySelectorAll('.js--video');

    for (let i = 0; i < $videoEmbeds.length; i += 1) {
      const $videoEmbed = $videoEmbeds[i];
      const $button = $videoEmbed.querySelector('.js--video__button');
      const $video = $videoEmbed.querySelector('.js--video__video');

      $button.addEventListener('click', () => {
        $button.classList.add('is-hidden');
        $video.setAttribute('src', $video.getAttribute('data-src'));
        $video.classList.remove('is-hidden');
      });
    }
  },
};

window.addEventListener('load', () => {
  clrs.embedVideo.init();
}, false);

const selector = {
	baseElement: ".js-navigation",
	triggerElement: ".js-navigation__trigger",
	menuElements: "data-menu-id",
	isSelected: "is-selected",
	isActive: "is-active",
};

class Navigation {
	constructor(element) {
		this.element = element;
		this.triggers = this.element.querySelectorAll(selector.triggerElement);
		this.menus = this.element.querySelectorAll(`[${selector.menuElements}]`);

		this.init();
	}

	_closeMenus() {
		this.menus.forEach((menu) => {
			menu.classList?.remove(selector.isActive);
		});
	}

	_setActiveMenu(element) {
		this.triggers.forEach((trigger) => {
			if (trigger.classList.contains(selector.isSelected)) {
				trigger.classList.remove(selector.isSelected);
			}
		});
		element.classList.add(selector.isSelected);
	}

	_toggleMenu(event) {
		const target = event.currentTarget.dataset?.target;
		const content = document.querySelector(
			`[${selector.menuElements}="${target}"]`,
		);
		content?.classList?.toggle(selector.isActive);
	}

	_bindEventListeners() {
		this.triggers.forEach((trigger) => {
			trigger.addEventListener("click", (evt) => {
				this._setActiveMenu(trigger);
				this._closeMenus();
				this._toggleMenu(evt);
			});
		});
	}

	init() {
		this._bindEventListeners();
	}
}

window.addEventListener(
	"load",
	() => {
		const navigationItems = document.querySelectorAll(selector.baseElement);
		navigationItems.forEach((item) => {
			return new Navigation(item);
		});
		return null;
	},
	false,
);

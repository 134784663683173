"use strict";

const mediaQuery = window.matchMedia("(max-width: 63.9375em)");

const selector = {
	baseElement: ".js-showhide",
	container: "[data-showhide-container]",
};
const classNames = {
	active: "is-active",
	targetActive: "is-selected",
	menuOpen: "no-scroll",
};
const dataAttributes = {
	hidden: "data-showhide-hidden-attribute",
};

class Showhide {
	constructor(component) {
		this.component = component;
		this.body = document.querySelector("body");

		this.toggleSelector = component.getAttribute("data-showhide-toggle");
		this.toggleTargets = this.toggleSelector
			? document.querySelectorAll(this.toggleSelector)
			: null;

		this.showSelector = component.getAttribute("data-showhide-show");
		this.showTargets = this.showSelector
			? document.querySelectorAll(this.showSelector)
			: null;

		this.hideSelector = component.getAttribute("data-showhide-hide");
		this.hideTargets = this.hideSelector
			? document.querySelectorAll(this.hideSelector)
			: null;

		this.closeSelector = component.getAttribute("data-showhide-close");
		this.closeTargets = this.closeSelector ? document.querySelectorAll(this.closeSelector) : null;

		this.container = component.closest(selector.container);

		this.init();
	}

	_toggleAttributes(element, attribute) {
		if (element.hasAttribute(attribute)) {
			element.removeAttribute(attribute);
		} else {
			element.setAttribute(attribute, "true");
		}
	}

	_handleToggleTargets() {
		// check if it is the same component that toggled before. Otherwise we probably should skip this?
		const componentId = this.component.dataset?.activestateSubdomain;
		const activeToggleTargets = [...this.toggleTargets]?.find( x => x?.classList.contains(classNames.active) && x?.dataset?.showhideId === componentId);

		this.component.classList.toggle(classNames.targetActive);
		this.component.setAttribute(
			"aria-expanded",
			this.component.classList.contains(classNames.targetActive),
		);

		if(activeToggleTargets) {
			this.toggleTargets.forEach((target) => {
				target.classList.toggle(classNames.active);
				this._toggleAttributes(target, "aria-hidden");
				target.hasAttribute('data-showhide-id') ? target.removeAttribute('data-showhide-id') : target.setAttribute('data-showhide-id', componentId);

				// if hamburger menu, then also change innerText
				if (target.hasAttribute("data-menu-hamburger")) {
					const wordElement = target.querySelector(".c-navigation__word");
					const text = target.classList.contains(classNames.targetActive)
						? wordElement.dataset.open
						: wordElement.dataset.close;

					wordElement.innerText = text;
				}
			});
		} else {
			this.toggleTargets.forEach((target) => {
				if (this.component.hasAttribute(dataAttributes.hidden)) {
					target.removeAttribute("hidden");
				} else {
					target.classList.add(classNames.active);
				}
				target.setAttribute('data-showhide-id', componentId);
				target.removeAttribute("aria-hidden");
			});
		}
	}

	_handleHideTargets() {
		const triggers = document.querySelectorAll(
			`[data-showhide-show="${this.hideSelector}"],[data-showhide-toggle="${this.hideSelector}"]`,
		);

		const submenu = this.component.closest('[data-submenu]');

		if (submenu && !mediaQuery.matches) {
			this.hideTargets = this.hideSelector ? submenu.querySelectorAll(this.hideSelector) : null;
		}

		this.hideTargets.forEach((target) => {
			if (this.component.hasAttribute(dataAttributes.hidden)) {
				target.setAttribute("hidden", "true");
			} else {
				target.classList.remove(classNames.active);
			}

			target.setAttribute("aria-hidden", "true");
		});
	}

	_handleShowTargets() {
		if (!this.toggleTargets || [...this.toggleTargets].filter(x => x.classList.contains(classNames.active)).length > 1) {
			//remove all targetActive from component's siblings
			const parent = this.component.parentElement.parentElement;

			parent?.querySelectorAll(`.${classNames.targetActive}`).forEach((child) => {
				child.classList?.remove(classNames.targetActive);
				child.setAttribute("aria-expanded", "false");
			});

			// add targetActive to click component
			this.component.classList.add(classNames.targetActive);
			this.component.setAttribute("aria-expanded", "true");
		}

		this.showTargets.forEach((target) => {
			if (this.component.hasAttribute(dataAttributes.hidden)) {
				target.removeAttribute("hidden");
			} else {
				target.classList.add(classNames.active);
			}

			target.removeAttribute("aria-hidden");
		});
	}

	_handleCloseTargets() {
		this.closeTargets.forEach((target) => {
			target.classList.remove(classNames.targetActive);
			target.setAttribute("aria-expanded", "false");
		});
	}

	init() {
		this.component.addEventListener("click", () => {
			if (this.toggleTargets) {
				this._handleToggleTargets();
			}
			if (this.hideTargets) {
				this._handleHideTargets();
			}
			if (this.showTargets) {
				this._handleShowTargets();
			}
			if (this.closeTargets) {
				this._handleCloseTargets();
			}

			if (mediaQuery.matches) {
				// set sessionStorage for reopening menu after navigation
				if (this.component.hasAttribute("data-menu-restore")) {
					sessionStorage.setItem("menuState", true);
				}

				setTimeout(() => {
					// check if any menu is open. if not, remove class from body
					const allActiveElements = Array.from(
						document.querySelectorAll(`.${classNames.active}`),
					);
					const allActiveDataMenuItems = allActiveElements.filter((el) => {
						return Array.from(el.attributes).some((attr) =>
							attr.name.startsWith("data-menu"),
						);
					});
					if (allActiveDataMenuItems?.length > 0) {
						this.body.classList.add(classNames.menuOpen);
					} else {
						this.body.classList.remove(classNames.menuOpen);
					}
				}, 100);
			}
		});

		if (mediaQuery.matches) {
			const openMenu = sessionStorage.getItem("menuState")?.length > 0 ? true : false;

			if (openMenu) {
				const restoreTrigger = document.querySelector(
					"[data-menu-restore-trigger]",
				);
				restoreTrigger.click();
				sessionStorage.removeItem("menuState");
			}
		}
	}
}

[...document.querySelectorAll(selector.baseElement)].forEach((item) => {
	new Showhide(item);
});

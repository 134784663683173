class Modal extends HTMLElement {
  static get observedAttributes() {
    return ["open"];
  }

  get controls() {
    return this.getAttribute("controls");
  }

  get type() {
    return this.getAttribute("type");
  }

  get open() {
    return this.hasAttribute("open");
  }

  set open(value) {
    if (value) {
      this.setAttribute("open", "");
    } else {
      this.removeAttribute("open");
    }
  }

  get setActiveTab() {
    return this.getAttribute("setactivetab");
  }

  toggleDetailSummary() {
    const isDeskView = window.matchMedia("(min-width: 1024px)");
    const detailSections = this.$modal.querySelectorAll(
      "details[auto-open-on-desk]",
    );

    if (isDeskView.matches) {
      detailSections.forEach((section) => {
        section.setAttribute("open", "");
      });
    }
  }

  connectedCallback() {
    this.$modal = document.querySelector(`#${this.controls}`);

    if (!this.$modal) return;

    this.$togglers = this.querySelectorAll(
      `[aria-controls="${this.controls}"]`,
    );

    this.$togglers.forEach(($toggler) => {
      $toggler.addEventListener("click", () => {
        this.open = !this.open;
      });
    });

    this.$modal.addEventListener("close", (e) => {
      this.open = false;
    });
  }

  attributeChangedCallback(name) {
    if (name === "open") {
      if (this.open) {
        this.$modal.addEventListener("click", (event) => {
          if (event.target.nodeName === "DIALOG") {
            this.$modal.close();
          }
        });

        this.$modal.showModal();

        if (this.type === "video") {
          this.querySelector("video-embed").dispatchEvent(
            new CustomEvent("start"),
          );
        }

        if (this.setActiveTab) {
          this.$modal
            .querySelector("tab-navigation")
            .setAttribute("activetab", this.setActiveTab);
        }

        this.toggleDetailSummary();

        return;
      }

      if (this.type === "video") {
        this.querySelector("video-embed").dispatchEvent(
          new CustomEvent("stop"),
        );
      }

      this.$modal.close();
    }
  }
}

customElements.define("custom-modal", Modal);

[...document.querySelectorAll('[data-layer-push]')].forEach(($el) => {
  $el.addEventListener('click', () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: $el.dataset.event,
      events: {
        category: $el.dataset.category,
        action: $el.dataset.action,
        label: $el.dataset.label,
      },
    });
  });
});

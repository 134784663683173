class Tooltip {
  constructor($el) {
    this.state = {
      isOpen: false,
    };

    this.$el = $el;
    this.$toggle = $el.querySelector('.js--tooltip__toggle');
    this.$tooltip = $el.querySelector('.js--tooltip__tooltip');
    this.$page = document.querySelector('.js--wrap-page');

    this.handleClickDocument = this.handleClickDocument.bind(this);
  }

  init() {
    this.handleClickTrigger();
  }

  handleClickTrigger() {
    this.$toggle.addEventListener('click', () => {
      if (!this.state.isOpen) {
        this.$el.classList.add('is-open');
        this.$tooltip.classList.add('is-open');
        this.state.isOpen = true;
        this.$page.addEventListener('click', this.handleClickDocument);
      } else {
        this.closeTooltip();
      }
    });
  }

  handleClickDocument(e) {
    if (!this.$el.contains(e.target)) {
      this.closeTooltip();
    }
  }

  closeTooltip() {
    this.$el.classList.remove('is-open');
    this.$tooltip.classList.remove('is-open');
    this.state.isOpen = false;
    this.$page.removeEventListener('click', this.handleClickDocument);
  }
}

[...document.querySelectorAll('.js--tooltip')].forEach(($el) => {
  const inst = new Tooltip($el);
  inst.init();
});

const moduleName = "submenu";
const baseJsClass = `js-${moduleName}`;

const cssClass = {
  activePanel: "is-selected",
  activeTrigger: "is-selected",
  trigger: `${baseJsClass}-trigger`,
};

const attributeName = {
  closeSelf: `data-${moduleName}-close-self`,
  groupName: `data-${moduleName}-group`,
  panel: `data-${moduleName}-panel`,
  targetPanel: `data-${moduleName}-panel-target`,
};

const selector = {
  activePanel: (groupName) => {
    return groupName === null
      ? `[${attributeName.panel}].${cssClass.activePanel}:not([${attributeName.groupName}])`
      : `[${attributeName.panel}][${attributeName.groupName}="${groupName}"].${cssClass.activePanel}`;
  },
  activeTrigger: (groupName) => {
    return groupName === null
      ? `.${cssClass.activeTrigger}:not([${attributeName.groupName}])`
      : `[${attributeName.groupName}="${groupName}"].${cssClass.activeTrigger}`;
  },
  panel: (panelName, groupName) => {
    return groupName === null
      ? `[${attributeName.panel}="${panelName}"]:not([${attributeName.groupName}])`
      : `[${attributeName.panel}="${panelName}"][${attributeName.groupName}="${groupName}"]`;
  },
  trigger: ".js-submenu-trigger",
};

/**
 * Handles the event which is dispatched when the user clicks on a trigger. It is our
 * cue to switch between visible parts of the menu
 */
function onTriggerClickedHandler(event) {
  const button = event.target;
  const allowCloseSelf = button.hasAttribute(attributeName.closeSelf);
  const groupName = button.hasAttribute(attributeName.groupName)
    ? button.getAttribute(attributeName.groupName)
    : null;
  const menuName = button.getAttribute(attributeName.targetPanel);
  const activePanel = document.querySelector(selector.activePanel(groupName));
  const activeTrigger = document.querySelector(
    selector.activeTrigger(groupName),
  );
  const targetPanel = document.querySelector(
    selector.panel(menuName, groupName),
  );
  const targetIsActive = activePanel === targetPanel;

  if (targetIsActive && !allowCloseSelf) {
    return;
  }

  if (activePanel !== null) {
    activePanel.classList.remove("is-selected");
  }
  if (activeTrigger !== null) {
    activeTrigger.classList.remove("is-selected");
  }

  if (
    activePanel === targetPanel &&
    !button.hasAttribute(attributeName.closeSelf)
  ) {
    return;
  }

  if (targetIsActive && allowCloseSelf) {
    return;
  }

  if (targetPanel !== null) {
    targetPanel.classList.add("is-selected");
  }
  button.classList.add("is-selected");
}

/**
 * Add event listeners to the elements which allow the user to switch between active menus.
 */
function bindEvents(baseElement) {
  const triggers = baseElement.querySelectorAll(".js-submenu-trigger");
  [...triggers].forEach((trigger) =>
    trigger.addEventListener("click", onTriggerClickedHandler),
  );
}

/**
 * Initialize the submenu functionality.
 */
function init() {
  const baseElement = document.querySelector(".js-submenu");
  if (baseElement === null) {
    return;
  }

  bindEvents(baseElement);
}

init();

class TabNavigation extends HTMLElement {
  static get observedAttributes() {
    return ["activetab"];
  }

  get activetab() {
    return this.getAttribute("activetab");
  }

  set activetab(value) {
    this.setAttribute("activetab", value);
  }

  get controlsAlso() {
    return this.getAttribute("controlsalso");
  }

  constructor() {
    super();
    this.handleClickTab = this.handleClickTab.bind(this);
    this.$panels = [...this.querySelectorAll("[role=tabpanel]")];
  }

  attributeChangedCallback(name) {
    if (name === "activetab") {
      this.updateDom();
    }
  }

  connectedCallback() {
    this.$tablist = this.querySelector("[role=tablist]");

    if (!this.$tablist) {
      return false;
    }

    this.$tabs = [...this.$tablist.querySelectorAll("[role=tab]")];
    this.$extraPanels = [...this.querySelectorAll(`[${this.controlsAlso}]`)];
    this.$tabs.forEach(($tab) => {
      $tab.addEventListener("click", this.handleClickTab);
    });
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.$tabs.forEach(($tab) => {
      $tab.removeEventListener("click", this.handleClickTab);
    });
  }

  handleClickTab(e) {
    this.activetab = e.target.id;
  }

  updateDom() {
    // set all tabs inactive
    this.$tabs.forEach(($tab) => {
      $tab.setAttribute("aria-selected", "false");
      $tab.setAttribute("tabindex", "-1");

      if ($tab.id === this.activetab) {
        // set 1 active tab
        $tab.setAttribute("aria-selected", "true");
        $tab.setAttribute("tabindex", "0");
      }
    });

    // set all panels hidden
    this.$panels.forEach(($panel) => {
      $panel.hidden = true;
    });

    // show 1 panel
    const $panelToActivate = this.$panels.find(
      ($panel) => $panel.getAttribute("aria-labelledby") === this.activetab,
    );
    $panelToActivate.hidden = false;

    // check if more things need to switch
    if (this.controlsAlso) {
      this.$extraPanels.forEach(($panel) => {
        $panel.hidden = true;
      });

      // show 1 extra panel
      const $extraPanelToActivate = this.$extraPanels.find(
        ($panel) => $panel.getAttribute("data-controlledby") === this.activetab,
      );
      $extraPanelToActivate.hidden = false;
    }
  }
}

customElements.define("tab-navigation", TabNavigation);

("use strict");

const selector = {
	baseElement: ".js-hamburger",
	iconElement: "[data-hamburger-icon]",
	wordElement: "[data-hamburger-word]",
};
const classNames = {
	activeClass: "is-active",
};

class Hamburger {
	constructor(element) {
		this.button = element;
		this.iconElement = this.button.querySelector(selector.iconElement);
		this.wordElement = this.button.querySelector(selector.wordElement);

		this._init();
	}

	_toggle() {
		this.iconElement.classList.toggle(classNames.activeClass);
		if (this.iconElement.classList.contains(classNames.activeClass)) {
			this.wordElement.innerText = this.wordElement.getAttribute("data-close");
		} else {
			this.wordElement.innerText = this.wordElement.getAttribute("data-open");
		}
	}

	_bindEvents() {
		this.button.addEventListener("click", () => this._toggle());
	}

	_init() {
		if (this.button) {
			this._bindEvents();
		}
	}
}

[...document.querySelectorAll(selector.baseElement)].forEach((item) => {
	new Hamburger(item);
});